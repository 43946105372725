import React from "react";
import _ from "lodash";
import htmlToReact from "../utils/htmlToReact";
import moment from "moment-strftime";

import { Layout } from "../components/index";
import safePrefix from "../utils/safePrefix";

export default class Post extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <div className="p-strip">
          <section className="page blog-post">
            <header className="hero">
              <h1 className="p-heading--two blog-post__heading">
                {_.get(this.props, "pageContext.frontmatter.title")}
              </h1>
              {_.get(
                this.props,
                "pageContext.frontmatter.content_img_path"
              ) && (
                <img
                  className="blog-post__image"
                  src={
                    "https://ik.imagekit.io/oi85dhs4k/tr:w-568" +
                    safePrefix(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.content_img_path"
                      )
                    )
                  }
                  alt=""
                />
              )}
              <div className="blog-post__subheading">
                {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                  <h2 className="p-heading--four">
                    {htmlToReact(
                      _.get(this.props, "pageContext.frontmatter.subtitle")
                    )}
                  </h2>
                )}
                <h3 className="p-heading--six">
                  {moment(
                    _.get(this.props, "pageContext.frontmatter.date")
                  ).strftime("%A, %B %e, %Y")}
                </h3>
              </div>
            </header>
            <div className="blog-post__content">
              {htmlToReact(_.get(this.props, "pageContext.html"))}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
